import * as React from "react"
import { Helmet } from 'react-helmet';

import HeaderBlock from "../blocks/HeaderBlock"
import AboutBlock from "../blocks/AboutBlock"
import ProjectBlock from "../blocks/ProjectsBlock"
import TagsBlock from "../blocks/TagsBlock"
import TimelineBlock from "../blocks/TimelineBlock"
import ContactBlock from "../blocks/ContactBlock"
import FooterBlock from "../blocks/FooterBlock"

import portfolio_data from "../portfolio_data"

export default function IndexPage() {
  const { header, about, tags, timelineItems, projects, contact } = portfolio_data;
  return (
    <div className="text-primary h-full">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Phil Deck</title>
        <link rel="canonical" href="https://phildeck.com" />
      </Helmet>
      <HeaderBlock name={header['name']} title={header['title']} />
      <hr className="border-t-2 border-primary" />
      <AboutBlock links={about['links']}>
        {about['content']}
      </AboutBlock>
      <TagsBlock baseTags={tags['base']} extraTags={tags['extra']} />
      <TimelineBlock timelineEvents={timelineItems} />
      <ProjectBlock projects={projects} />
      <ContactBlock formId={contact['formId']} />
      <FooterBlock />
    </div>
  )
}