import React from 'react'

const SkillTag = ({ skillName, className }) => {
    return (
        <div className={`border-dashed border-2 border-secondary px-5 py-3 font-bold ${className}`}>
            {skillName}
        </div>
    )
}

export default SkillTag