import React from 'react'
import ProjectItem from '../components/ProjectItem'


const ProjectBlock = ({ projects }) => {

    const renderProjects = (project) => {
        return project.map((data, idx) => <ProjectItem key={idx} {...data}>{data['content']}</ProjectItem>)
    }

    return (
        <div className='bg-primary text-secondary'>
            <div className='mx-auto w-11/12 md:w-2/3 py-16'>
                <div className='text-center md:text-left basis-1/3'>
                    <h3 className='font-medium text-4xl'>Projects</h3>
                </div>
                {renderProjects(projects)}
            </div>
        </div>
    )
}

export default ProjectBlock