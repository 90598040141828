import React from 'react'
import TimelineItem from '../components/TimelineItem'

const TimelineBlock = ({ timelineEvents }) => {

    const renderTimelineItems = (itemData) => {
        return itemData.map((data, idx) => <TimelineItem key={idx} {...data}>{data['content']}</TimelineItem>)
    }

    return (
        <div className='mx-auto w-11/12 md:w-2/3 py-16'>

            <div className='text-secondary text-center md:text-left basis-1/3 mb-10'>
                <h3 className='font-medium text-4xl'>Experience</h3>
            </div>

            <div className="relative wrap overflow-hidden h-full pr-1 md:pr-0">

                {/* Timeline */}
                <div className="absolute bg-gradient-to-b from-primary via-primary  to-white h-full w-0.5 left-6 md:left-[33.3%]"></div>

                {renderTimelineItems(timelineEvents)}

            </div>

        </div>
    )
}

export default TimelineBlock