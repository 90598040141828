import React, { useState } from 'react'
import SkillTag from '../components/SkillTag'


const TagsBlock = ({ baseTags, extraTags }) => {

    const [toggled, setToggle] = useState(false)

    const renderTags = () => {
        const tagsToRender = toggled ? baseTags.concat(extraTags): baseTags;
        return tagsToRender.map((skill, idx) => <div key={idx} className='mx-3 my-2'><SkillTag skillName={skill} /></div>)
    }

    const renderIcon = () => {
        if (toggled) {
            return (
                <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
                </svg>
            )
        } else {
            return (
                <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
            )
        }
    }

    const toggleExtraTags = (e) => {

        setToggle(!toggled)
    }

    return (
        <div className='bg-primary text-secondary'>
            <div className='mx-auto w-11/12 md:w-1/2 py-2'>
                <div className='flex justify-center flex-wrap mx-3 mb-2'>
                    {renderTags()}
                </div>
                <div className='flex justify-center'>
                    <span
                        onClick={toggleExtraTags}
                        className="inline-flex items-center transition duration-100 ease-linear border-2 text-white hover:text-secondary border-secondary w-8 h-8 rounded-full cursor-pointer">
                        <h1 className="mx-auto font-semibold text-lg cursor-pointer">
                            {renderIcon()}
                        </h1>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default TagsBlock