import React from "react"

const portfolio_data = {
    header: {
        name: "Philip Deck",
        title: "Software Engineer"
    },
    about: {
        content: (
            <React.Fragment>
                Hey there! My name is Philip, but you can call me Phil. I'm a software developer based in the Ottawa region.
                I started programming at the age of 14 when I used the Source Development Kit to make GarrysMod models, scripts and maps. I have written code ever since, from simple fun web games to full scale enterprise web applications, I love solving problems with elegant solutions.
                When not in front of my computer, you can find me at the gym, hanging out with friends or helping out at my family's farm.
                <br />
                <br />
                I also help people create software. If you need a web application of any kind, send me a message and I would love to discuss it with you!
            </React.Fragment>
        ),
        links: ["https://github.com/deck-phil/", "https://linkedin.com/in/philip-deck/"]
    },
    tags: {
        base: ["Python", "JavaScript", "MySQL", "Docker", "React"],
        extra: ["Django", "Flask", "AWS", "Redis", "Jenkins"]
    },
    timelineItems: [
        {
            yearRange: "2022-Present",
            workplace: "Bell",
            type: "work",
            position: "Software Engineer",
            content: (
                <React.Fragment>
                    Currently at Bell Canada, I help develop and upkeep voice automation tools.
                </React.Fragment>
            ),
            tags: ["Python", "Kubernetes", "Apache NiFi"],
        },
        {
            yearRange: "2021-Present",
            workplace: "Freelance",
            type: "freelance",
            position: "Software Consultant",
            content: (
                <React.Fragment>
                    I offer my services to any organization which needs a specialized web application to solve real world problems.
                    I sit down with customers to analyze needs and requirements, explore potential solutions, then develop and deploy them.
                </React.Fragment>
            ),
            tags: ["JavaScript", "Django", "MySQL", "AWS"],
        },
        {
            yearRange: "2021-2022",
            workplace: "Cisco",
            type: "work",
            position: "Software Engineer",
            content: (
                <React.Fragment>
                    I engineered and developed an internal tool at Cisco to help hardware engineers track user stories and engineering items.
                    This web application connects to a graph database, visualizes the items and relationship between each in a timeline and node-style graph.
                </React.Fragment>
            ),
            tags: ["Angular", "Django", "SQLite", "Neo4j"],
        },
        {
            yearRange: "2018-2021",
            workplace: "Mobile Knowledge",
            type: "work",
            position: "Full Stack Python Developer",
            content: (
                <React.Fragment>
                    While at Mobile Knowledge, I played a critical role in developing and maintaining a taxi dispatching web application.
                    Daily tasks included meeting with clients to analyze customer needs, developing new features and discovering new technologies to implement into the current system.
                </React.Fragment>
            ),
            tags: ["JavaScript", "Django", "Flask", "MySQL"],
        },
        {
            yearRange: "2016-2018",
            workplace: "Algonquin College",
            type: "school",
            position: "Computer Programming",
            content: (
                <React.Fragment>
                    I studied object oriented programming and basic software engineering principles while at Algonquin with a heavy focus on Java and Python.
                </React.Fragment>
            ),
            tags: []
        }
    ],
    projects: [
        {
            title: "Powerblast",
            position: "Lead Engineer",
            content: (
                <React.Fragment>
                    LCO firm reached out to me to design and develop an inventory management system for one of their clients, I was more than happy to help them out.
                    With the help of LCO's Alex Carmichael, we were able to deliver this feature rich application with style.
                    Some key features include product and order management and a center for several inventory reports.
                    <br /><br />
                    On a technical note, this application is taking advantage of Django's powerful ORM connecting to a MySQL database.
                    The front end is simply using Jquery and plain Javascript and was styled with CSS by a member of the LCO team, with input from myself.
                </React.Fragment>
            ),
            tags: ["JavaScript", "Django", "MySQL", "AWS"],
            links: []
        },
        {
            title: "Gym Booker",
            position: "Creator",
            content: (
                <React.Fragment>
                    During the Covid 19 pandemic, Ontario's gyms moved to a booking system in order to control the amount of gym goers. Timeslots were announced 2 days in advance and you needed to login and book it online via web portal.
                    I've created a program to run every week and book my preferred gym time. Since this runs as soon as the spots open, I almost always garantee the time slot I want.

                    This script is written in python and using the selenium web driver to simulate a web browser.
                </React.Fragment>
            ),
            tags: ["Selenium", "Flask", "MongoDB"],
            links: ["https://github.com/deck-phil/gym-booker"]
        }
    ],
    contact: {
        formId: "mzboggkr"
    }
};

export default portfolio_data