import React from 'react'
import { useForm, ValidationError } from '@formspree/react';


function ContactForm({ formId }) {
    const [formState, handleSubmit] = useForm(formId);

    if (formState.succeeded) {
        return (
            <div className='text-center'>
                <h3 className='text-secondary font-medium text-4xl mb-6'>Thanks for the message!</h3>
                <h3 className='font-medium text-2xl italic'>I'll get back to you ASAP!</h3>
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-6">
                <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    required
                    className="transition ease-linear duration-150 bg-gray-200 focus:bg-gray-300 rounded-lg block w-full p-2.5 border-none focus:outline-none" />
                <ValidationError
                    prefix="Email"
                    field="email"
                    errors={formState.errors}
                />
            </div>
            <div className="mb-6">
                <textarea
                    id="message"
                    name="message"
                    placeholder="Message"
                    rows="5"
                    required
                    className="resize-none transition ease-linear duration-150 bg-gray-200 focus:bg-gray-300 rounded-lg block w-full p-2.5 border-none focus:outline-none" />
                <ValidationError
                    prefix="Message"
                    field="message"
                    errors={formState.errors}
                />
            </div>
            <button type="submit" disabled={formState.submitting} className="transition ease-linear duration-100 text-white bg-primary hover:bg-secondary font-medium rounded-lg text-sm w-full  md:w-auto px-5 py-2.5 text-center ">Submit</button>
        </form>
    )
}
export default ContactForm

