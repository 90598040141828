import React from 'react'
import IconLink from './IconLink'
import SkillTag from './SkillTag'


const ProjectItem = ({ title, position, links, tags, children }) => {

    const renderTags = (tags) => {
        return tags.map((skill, idx) => <div key={idx} className='mr-3 my-2'><SkillTag skillName={skill} /></div>)
    }

    const renderLinks = (links) => {
        return links.map((link, idx) => <div key={idx} className='mr-3 my-2'><IconLink url={link} /></div>)
    }

    return (
        <div className='mt-10'>
            <h3 className="mb-3 font-bold text-2xl">{title}</h3>
            <div className='border-dashed border-2 border-secondary px-5 py-3 overflow-auto'>
                <h3 className='mb-2 font-bold text-xl text-secondary italic'>{position}</h3>
                <p>
                    {children}
                </p>
                <div className='flex justify-end'>
                    {renderLinks(links)}
                </div>
            </div>
            <div className='flex justify-start flex-wrap'>
                {renderTags(tags)}
            </div>
        </div>

    )
}

export default ProjectItem