import React from 'react'
import SkillTag from './SkillTag'

const TimelineItem = ({ yearRange, workplace, type, position, tags, children }) => {

    const renderIcon = (type) => {
        switch (type) {
            case "work":
                return (
                    <svg className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                )
            case "school":
                return (
                    <svg className="h-8 w-8 text-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                        <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                        <line x1="3" y1="6" x2="3" y2="19" />
                        <line x1="12" y1="6" x2="12" y2="19" />
                        <line x1="21" y1="6" x2="21" y2="19" />
                    </svg>
                )
            case "freelance":
                return (
                    <svg className="h-8 w-8 text-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <circle cx="12" cy="7" r="4" />
                        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    </svg>
                )
            default:
                return null
        }
    }

    const renderTags = (tags) => {
        if (!tags)
            return null
        return tags.map((skill, idx) => <div key={idx} className='mr-3 my-2'><SkillTag skillName={skill} className="border-primary" /></div>)
    }

    return (
        <div className="mb-16 flex justify-between w-full">
            <div className="order-1 w-3/12 text-right mt-2 hidden md:block">
                <h3 className="mb-3 font-normal text-xl md:visible invisible">{yearRange}</h3>
            </div>
            <div className="z-20 flex items-center order-2 bg-secondary w-12 h-12 rounded-full">
                <h1 className="mx-auto font-semibold text-lg text-white">
                    {renderIcon(type)}
                </h1>
            </div>
            <div className="order-3 w-10/12 md:w-7/12 mt-2">
                <h3 className="mb-3 font-normal text-xl md:hidden">{yearRange}</h3>
                <h3 className="mb-3 font-bold text-xl">{workplace}</h3>
                <div className="border-dashed border-2 border-primary px-4 py-3">
                    <h3 className="mb-2 text-lg text-secondary italic">{position}</h3>
                    <p className="text-sm leading-snug tracking-wide text-opacity-100">
                        {children}
                    </p>
                </div>
                <div className='flex justify-start flex-wrap'>
                    {renderTags(tags)}
                </div>
            </div>

        </div>
    )
}

export default TimelineItem