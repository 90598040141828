import React from 'react'
import header from '../images/header.webp';


const HeaderBlock = ({ name, title }) => {
    return (
        <div className="flex h-screen bg-cover bg-[center_center]" style={{ backgroundImage: `url(${header})` }}>
            <div className="flex w-screen bg-white bg-opacity-60">
                <div className="m-auto text-center">
                    <h1 className="font-bold leading-tight text-9xl text-primary">{name}</h1>
                    <h1 className="leading-tight text-4xl text-secondary">{title}</h1>
                </div>
            </div>
        </div>
    )
}

export default HeaderBlock