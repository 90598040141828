import React from 'react'
import ContactForm from '../components/ContactForm'


const ContactBlock = ({ formId }) => {
    return (
        <div className='mx-auto w-11/12 md:w-2/3 py-16'>
            <div className='md:flex'>
                <div className='text-secondary text-center md:text-left basis-1/3 mb-10'>
                    <h3 className='font-medium text-4xl'>Contact</h3>
                </div>
                <div className='basis-2/3 text-left'>
                    <ContactForm formId={formId} />
                </div>
            </div>
        </div>
    )
}

export default ContactBlock