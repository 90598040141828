import React from 'react'


const FooterBlock = () => {
    return (
        <div className='bg-primary text-secondary text-lg font-medium'>
            <div className='mx-auto w-11/12 md:w-2/3 py-8'>
                <div className='text-center'>
                    Copyright 2022 | Philip Deck
                </div>
                <div className='text-center'>
                    Created by Me
                </div>
                {/* <div className='flex flex-col md:flex-row md:space-x-6 space-y-2 items-center justify-center'>
                    <a>Home</a>
                    <a>About</a>
                    <a>Experience</a>
                    <a>Projects</a>
                    <a>Contact</a>
                </div> */}
            </div>
        </div>
    )
}

export default FooterBlock
