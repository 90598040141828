import React from 'react'
import IconLink from '../components/IconLink'

const AboutBlock = ({ links, children }) => {

    const renderLinks = (links) => {
        return links.map((link, idx) => <div key={idx} className='mr-3 my-2'><IconLink url={link} /></div>)
    }

    return (
        <div className='mx-auto w-11/12 md:w-2/3 py-16'>
            <div className='md:flex'>
                <div className='text-secondary text-center md:text-left basis-1/3 mb-10'>
                    <h3 className='font-medium text-4xl'>About</h3>
                </div>
                <div className='basis-2/3 overflow-auto'>
                    <p>
                        {children}
                    </p>
                    <div className='flex justify-start mt-2'>
                        {renderLinks(links)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutBlock